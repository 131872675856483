/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { IConfigurationModel } from "@nf/common";
import { AppHeaderVisibility } from "enums/AppHeaderVisibility";
import { AppHeaderMobile } from "./AppHeaderMobile";
import { AppHeaderDesktopTablet } from "./AppHeaderDesktopTablet";
import { useBreakpoints } from "../Responsive";

export interface IAppHeaderProps {
  configuration?: IConfigurationModel;
  visibility?: AppHeaderVisibility;
  isLoggedIn?: boolean;
  isChild?: boolean;
}

export const AppHeader: React.FC<IAppHeaderProps> = ({
  configuration,
  visibility = AppHeaderVisibility?.Default,
  isLoggedIn = false,
  isChild = false,
}) => {
  const breakPoints = useBreakpoints();

  return breakPoints.isXS ? (
    <AppHeaderMobile
      configuration={configuration}
      visibility={visibility}
      isLoggedIn={isLoggedIn}
    />
  ) : (
    <AppHeaderDesktopTablet
      configuration={configuration}
      visibility={visibility}
      isLoggedIn={isLoggedIn}
      isChild={isChild}
    />
  );
};

export default AppHeader;
