/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React, { memo, useMemo } from "react";

import {
  IApplicationMenuScreenModel,
  IConfigurationModel,
  ScreenType,
  useUserSubprofilesSelector,
} from "@nf/common";
import dynamic from "next/dynamic";

import { AppHeaderVisibility } from "enums/AppHeaderVisibility";
import { HeaderLogo } from "../components";

const AppHeaderSignUpMobile = dynamic(
  () => import("../AppHeaderSignUpMobile/AppHeaderSignUpMobile")
);

const ApplicationHeader = dynamic(() => import("../../ApplicationHeader"));
const ApplicationMenu = dynamic(() => import("../../ApplicationMenu"));
const HeaderSignIn = dynamic(
  () => import("../components/HeaderSignInDesktopTablet")
);
const HeaderUserInfoMobile = dynamic(
  () => import("../components/HeaderUserInfoMobile"),
  { ssr: false }
);

export interface IAppHeaderMobileProps {
  configuration?: IConfigurationModel;
  visibility?: AppHeaderVisibility;
  isLoggedIn: boolean;
  allowLinkRedirection?: boolean;
}
export const AppHeaderMobile = memo(
  ({
    configuration,
    visibility = AppHeaderVisibility?.Default,
    isLoggedIn,
    allowLinkRedirection = true,
  }: IAppHeaderMobileProps) => {
    const { Data } = useUserSubprofilesSelector();

    const applicationMenuScreenData = useMemo(() => {
      if (configuration && configuration.Screens) {
        const screen = configuration.Screens[ScreenType.ApplicationMenu];
        return screen as IApplicationMenuScreenModel | undefined;
      }
      return undefined;
    }, [configuration]);

    const showHeader = !!(visibility & AppHeaderVisibility.Header);

    return (
      <header className="headerMobile">
        {!isLoggedIn && showHeader && <AppHeaderSignUpMobile />}
        <div className="headerTop">
          <div className="headerLeft">
            <HeaderLogo
              configuration={configuration}
              allowLinkRedirection={allowLinkRedirection}
            />
          </div>
          <div className="headerRight">
            {showHeader && (
              <ApplicationHeader
                items={configuration?.Screens?.APPLICATION_HEADER?.Components}
              />
            )}
            {!!(visibility & AppHeaderVisibility.UserInfo) && (
              <HeaderUserInfoMobile
                subprofilesState={Data}
                isLoggedIn={isLoggedIn}
              />
            )}
            {!!(visibility & AppHeaderVisibility.SignIn) && <HeaderSignIn />}
          </div>
        </div>

        <div className="headerBottom">
          {!!(visibility & AppHeaderVisibility?.Menu) && (
            <ApplicationMenu screen={applicationMenuScreenData} />
          )}
        </div>
      </header>
    );
  }
);

export default AppHeaderMobile;
