/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IConfigurationModel, IUserSubprofilesStateModel } from "@nf/common";
import { ROUTES } from "@nf/constants";

import dynamic from "next/dynamic";
import React, { useCallback } from "react";
import cx from "classnames";

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

const DEFAULT_LOGO = "/assets/app/app-header-logo-transparent.png";

interface IHeaderLogoProps {
  noMargin?: boolean;
  profile?: IUserSubprofilesStateModel;
  configuration?: IConfigurationModel;
  allowLinkRedirection?: boolean;
  className?: string;
}

export const HeaderLogo = ({
  noMargin = false,
  profile,
  configuration,
  allowLinkRedirection = true,
  className,
}: IHeaderLogoProps) => {
  const headerClassName = noMargin ? "headerNoMargin" : "headerBrandLogo";
  const link = profile?.currentSubprofile?.Child ? ROUTES.KIDS : ROUTES.HOME;

  const renderLogo = useCallback(() => {
    const logo =
      configuration?.Brandings?.MAIN?.WebHeaderLogoUrl ?? DEFAULT_LOGO;
    return <img alt="Logo" className="logo" src={logo} />;
  }, [configuration?.Brandings?.MAIN?.WebHeaderLogoUrl]);

  return (
    <div className={cx(headerClassName, className)}>
      {allowLinkRedirection ? (
        <LinkWithLocale href={link}>
          <a>{renderLogo()}</a>
        </LinkWithLocale>
      ) : (
        renderLogo()
      )}
    </div>
  );
};

export default HeaderLogo;
