/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IUserInfoModel,
  IUserSubprofileModel,
  IUserSubprofilesStateModel,
  RouteHelper,
  useUserSubprofilesSelector,
} from "@nf/common";
import { ROUTES } from "@nf/constants";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useIsTabletDown } from "hooks/useIsTabletDown";

const HeaderSignIn = dynamic(() => import("../HeaderSignInDesktopTablet"));

const SearchInput = dynamic(
  () => import("screens/SearchScreen/SearchInput/SearchInput"),
  { ssr: false }
);
const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));
const Portal = dynamic(() => import("components/Portal"), {
  ssr: false,
});
const ModalItem = dynamic(() => import("./components/ModalItem"), {
  ssr: false,
});
const EnterPinCodeScreen = dynamic(
  () => import("screens/SubprofileScreen/EnterPinCodeScreen"),
  { ssr: false }
);
const SearchButton = dynamic(
  () => import("screens/SearchScreen/SearchInput/SearchButton"),
  { ssr: false }
);

import { ModalItemType } from "./enums";

import cx from "classnames";
import Burger from "resources/icons/burger.svg";
import Plus from "resources/icons/add-circle.svg";
import styles from "./HeaderUserInfo.module.scss";
import { useRoutePathBuild } from "../../../../hooks";
import { useSetCurrentSubProfile } from "../../../../hooks";
import { useIsLoading } from "../../../../helpers/setIsLoadingContext";

export interface IHeaderUserInfoProps {
  user?: IUserInfoModel;
  isLoggedIn: boolean;
  subprofilesState?: IUserSubprofilesStateModel;
}

export interface IModalItem {
  key: string | undefined;
  title: string | undefined;
  firstLetter?: string;
  icon?: any;
  avatarColor?: string;
  onClick: () => void;
}

export const HeaderUserInfo = ({
  isLoggedIn,
  subprofilesState,
}: IHeaderUserInfoProps) => {
  const { Data: subProfilesData, Completed } = useUserSubprofilesSelector();
  const { t } = useTranslation("translations");
  const [selectedAuthSubprofile, setSelectedAuthSubprofile] = useState<
    IUserSubprofileModel | undefined
  >(undefined);
  const [modal, setModal] = useState(false);
  const router = useRouter();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isTabletDown } = useIsTabletDown();

  const selectCurrentProfile = useSetCurrentSubProfile();

  const currentSubprofile = subprofilesState?.currentSubprofile;
  const subprofilesList = subprofilesState?.subprofiles;
  const pinCodeId = subprofilesState?.auth?.PinCodeId;
  const { setIsLoading } = useIsLoading();

  const isActive = (iconTitle: string) => {
    return iconTitle === router.asPath.slice(1, router.asPath.length + 1);
  };

  const routePrefix = useRoutePathBuild();

  const closeEnterPinCodeScreen = () => setSelectedAuthSubprofile(undefined);

  const handleSelectProfile = async (subProfile?: IUserSubprofileModel) => {
    await selectCurrentProfile(subProfile);
    closeEnterPinCodeScreen();
    setIsLoading(true);
  };

  const handleClick = useCallback(
    (e: MouseEvent) => {
      const modalRef = wrapperRef.current;
      if (modalRef && modalRef.contains(e.target as Node)) {
        return;
      }
      setModal(false);
    },
    [modal]
  );

  //Subprofiles
  const modalItems: IModalItem[] | undefined = useMemo(() => {
    const items: IModalItem[] | undefined = subprofilesList?.map(
      (subprofile) => ({
        key: subprofile.UserId,
        title: subprofile.DisplayName?.split(" ")[0],
        firstLetter: subprofile.FirstLetter,
        avatarColor: subprofile.Color,
        onClick: () => {
          if (subprofile.UserId === currentSubprofile?.UserId) return;

          if (pinCodeId && currentSubprofile?.Child && !subprofile?.Child) {
            setSelectedAuthSubprofile(subprofile);
          } else {
            handleSelectProfile(subprofile);
          }
        },
      })
    );

    if (currentSubprofile?.Owner) {
      (items?.length ?? 0) < 5 &&
        items?.push({
          key: ModalItemType.AddProfile,
          title: t("web_subprofiles_nav_item_add_profile"),
          icon: <Plus />,
          onClick: () => {
            setModal(false);
            router.push({
              pathname: `${routePrefix}${ROUTES.SETTINGS_ADD_SUBRPOFILE}`,
            });
          },
        });

      items?.push({
        key: ModalItemType.ManageProfiles,
        title: t("web_subprofiles_nav_item_manage_profiles"),
        onClick: () => {
          setModal(false);
          router.push({
            pathname: `${routePrefix}${ROUTES.SETTINGS_SUBPROFILE_SCREEN}`,
          });
        },
      });
    }
    return items ?? [];
  }, [subprofilesList, currentSubprofile, pinCodeId, routePrefix]);

  useEffect(() => {
    if (modal) {
      document.addEventListener("click", handleClick);
    }
    return () => document.removeEventListener("click", handleClick);
  }, [modal]);

  const modalContent = useMemo(() => {
    return (
      <>
        {modalItems?.map((item) => (
          <ModalItem
            key={item.key}
            item={item}
            currentSubprofileId={currentSubprofile?.UserId}
          />
        ))}
      </>
    );
  }, [modalItems]);

  if (!isLoggedIn) return <HeaderSignIn />;
  const showUserMenu =
    isLoggedIn && Completed && subProfilesData?.currentSubprofile;
  if (showUserMenu) {
    return (
      <>
        {!isTabletDown ? (
          <SearchInput />
        ) : (
          <SearchButton
            isSearchInputExpanded={false}
            onClick={() => null}
            isSearchPage={false}
          />
        )}
        <div className={styles.iconsWrapper}>
          {!currentSubprofile?.Child && (
            <LinkWithLocale
              href={RouteHelper.goToSettings("personal-details") || ROUTES.HOME}
            >
              <a
                role="button"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Burger
                  className={cx(styles.burger, {
                    [styles.iconActive]: isActive("settings"),
                  })}
                />
              </a>
            </LinkWithLocale>
          )}
        </div>
        {currentSubprofile && (
          <div className={styles.profileWrapper} ref={wrapperRef}>
            <div
              className={styles.profileHeader}
              onClick={() => setModal(true)}
            >
              <div
                className={styles.avatar}
                style={{
                  backgroundColor: currentSubprofile.Color,
                }}
              ></div>
              <div className={styles.username}>
                {currentSubprofile?.FirstLetter}
              </div>
            </div>

            {modal && (
              <div className={styles.modal} onClick={() => setModal(false)}>
                {modalContent}
              </div>
            )}
          </div>
        )}
        {selectedAuthSubprofile && pinCodeId && (
          <Portal>
            <EnterPinCodeScreen
              handleSelectProfile={handleSelectProfile}
              pinCodeId={pinCodeId}
              subprofile={selectedAuthSubprofile}
              close={closeEnterPinCodeScreen}
            />
          </Portal>
        )}
      </>
    );
  }
  return null;
};

export default HeaderUserInfo;
