/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import dynamic from "next/dynamic";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { InView } from "react-intersection-observer";
import cx from "classnames";

import {
  IApplicationMenuScreenModel,
  IAppState,
  IConfigurationModel,
  ScreenType,
  useUserSubprofilesSelector,
} from "@nf/common";

import { AppHeaderVisibility } from "enums/AppHeaderVisibility";

const HeaderLogo = dynamic(() => import("../components/HeaderLogo"));
const HeaderSignIn = dynamic(
  () => import("../components/HeaderSignInDesktopTablet")
);
const HeaderUserInfo = dynamic(() => import("../components/HeaderUserInfo"));
const ApplicationMenu = dynamic(() => import("../../ApplicationMenu"));
const ApplicationHeader = dynamic(() => import("../../ApplicationHeader"));

//import styles from "./AppHeaderDesktopTablet.module.scss";

export interface IAppHeaderProps {
  configuration?: IConfigurationModel;
  visibility?: AppHeaderVisibility;
  isLoggedIn: boolean;
  isChild?: boolean;
}

export const AppHeaderDesktopTablet = ({
  configuration,
  visibility = AppHeaderVisibility?.Default,
  isLoggedIn,
  isChild = false,
}: IAppHeaderProps) => {
  const [isInPrimaryView, setIsInPrimaryView] = useState(true);
  let applicationMenuScreenData: IApplicationMenuScreenModel | undefined =
    undefined;
  const appState = useSelector((state: IAppState) => state.auth);
  const { Data } = useUserSubprofilesSelector();

  if (configuration && configuration.Screens) {
    const screen = configuration.Screens[ScreenType.ApplicationMenu];

    if (screen) {
      applicationMenuScreenData = screen as IApplicationMenuScreenModel;
    }
  }

  const renderHeader = useMemo(() => {
    return (
      <header
        className={cx("headerTabletDesktop", {
          ["headerTop"]: isInPrimaryView,
        })}
      >
        <div className={"headerLeft"}>
          <HeaderLogo profile={Data} configuration={configuration} />
          {!!(visibility & AppHeaderVisibility?.Menu) && (
            <ApplicationMenu
              screen={applicationMenuScreenData}
              isChild={isChild}
            />
          )}
        </div>
        <div className="headerRight">
          {!!(visibility & AppHeaderVisibility?.Header) && (
            <ApplicationHeader
              items={configuration?.Screens?.APPLICATION_HEADER?.Components}
            />
          )}
          {!!(visibility & AppHeaderVisibility.UserInfo) && (
            <HeaderUserInfo
              subprofilesState={Data}
              user={appState.user}
              isLoggedIn={isLoggedIn}
            />
          )}
          {!!(visibility & AppHeaderVisibility?.SignIn) && <HeaderSignIn />}
        </div>
      </header>
    );
  }, [isInPrimaryView, configuration, visibility, appState, isLoggedIn, Data]);
  return (
    <InView onChange={(inView) => setIsInPrimaryView(inView)}>
      {renderHeader}
    </InView>
  );
};

export default AppHeaderDesktopTablet;
