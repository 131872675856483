/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useEffect, useState } from "react";
import { debounce } from "debounce";
import { TABLET_MAX_WIDTH } from "constants/dimensions";

export function useIsTabletDown() {
  const [isTabletDown, setIsTabletDown] = useState(false);

  const checkForTabletWidth = () => {
    if (window.innerWidth <= TABLET_MAX_WIDTH) {
      setIsTabletDown(true);
    } else {
      setIsTabletDown(false);
    }
  };

  const checkForTabletWidthDebounced = debounce(checkForTabletWidth, 150);

  useEffect(() => {
    checkForTabletWidth();
    screen.orientation?.addEventListener("change", checkForTabletWidth);
    window.addEventListener("resize", checkForTabletWidthDebounced);

    return () => {
      screen.orientation?.removeEventListener("change", checkForTabletWidth);
      window.removeEventListener("resize", checkForTabletWidthDebounced);
    };
  }, []);

  return {
    isTabletDown,
  };
}
