/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { ROUTES } from "@nf/constants";
import React from "react";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import styles from "./HeaderSignInDesktopTablet.module.scss";

const MediaButton = dynamic(() => import("components/MediaButton"), {
  ssr: false,
});
const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"), {
  ssr: false,
});

const SearchButton = dynamic(
  () => import("screens/SearchScreen/SearchInput/SearchButton"),
  { ssr: false }
);

const SearchInput = dynamic(
  () => import("screens/SearchScreen/SearchInput/SearchInput"),
  { ssr: false }
);

export const HeaderSignInDesktopTablet = () => {
  const { t } = useTranslation("translations");
  const router = useRouter();
  const isSearchPage = router.asPath.includes(ROUTES.SEARCH_SCREEN);
  return (
    <div className={styles.container}>
      <div className={styles.searchButton}>
        {!isSearchPage ? (
          <SearchButton
            isSearchInputExpanded={false}
            onClick={() => null}
            isSearchPage={false}
          />
        ) : (
          <SearchInput />
        )}
      </div>
      <LinkWithLocale href={ROUTES.LOGIN}>
        <a>
          <MediaButton
            className={styles.signInButton}
            tabIndex={-1}
            variant="plain"
          >
            {t("web_login_button")}
          </MediaButton>
        </a>
      </LinkWithLocale>
    </div>
  );
};

export default HeaderSignInDesktopTablet;
